<template>
  <section id="company" ref="companyContent">
    <PageTitle :title="$t('company.title')" :breadcrumbs="breadcrumbs"></PageTitle>
    
    <div class="wrapper wrapper-content animated fadeInRight">

      <Header></Header>

      <div class="row" v-if="selectedModule">
        <div class="col">
          <Company></Company>
        </div>
      </div>

    </div>
  </section>
</template>


<script lang="ts">




import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { authenticationTypes } from '@fwk-client/store/types';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';

import { useGenericPage } from '@fwk-client/composables/useGenericPage';
import { useModuleAdmin } from '../../composables/useModuleAdmin';

import Company from '../panels/Company.vue';
import Header from '../panels/Header.vue';
import PageTitle from '../../../../components/panels/PageTitle.vue';
import { enableIbox, disableIbox} from '../../../../theme/inspinia-jquery'; 


export default defineComponent({
  props: {
  },
  components: {
    PageTitle,
    Company,
    Header
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const genericPage = useGenericPage(props, context);
    
    const { modules, selectedModule } = useModuleAdmin(props, context);

    const companyContent:Ref<HTMLElement|null> = ref(null);

    const breadcrumbs = [{
      label: app.$t('home.dashboard')
    },
    {
      label: app.$t('company.title')
    }]

    const hasUserRole = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];
    const listLoading = computed(() => {
      return false;
    })

    onMounted(() => {
      enableIbox(companyContent.value!);
    })

    onBeforeUnmount(() => {
      disableIbox(companyContent.value!);
    })

    return {
      companyContent,
      breadcrumbs,
      selectedModule,
      hasUserRole,
      apiRoles,
      listLoading
    }
  }
});
</script>