<template>
  <div class="ibox companies">
    <div class="ibox-title">
      <h2>{{ $t('company.company.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (isListLoading ? ' sk-loading' : '')">

      <div v-if="isListLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <div class="row m-b-lg m-t-lg" v-if="company != undefined">
        <div class="col-md-6">
          <div v-if="company.logo" class="profile-image">
              <img :src="getStaticURLFromPath(company.logo.path)" class="rounded-circle circle-border m-b-md" alt="logo">
          </div>
          <div :class="(company.logo ? 'profile-info' : '')">
            <h2 class="no-margins">{{company.name}}</h2>
            <p>{{$t('company.info.number',{companyID: company._id})}}</p>
          </div>
        </div>
      </div>

      <div class="row" v-if="company != undefined">
        <div class="col-lg-4">
          <Info :company="company"></Info>
          <div v-if="company.users != null && company.users.length > 0">
            <ul>
              <li v-for="(user, index) in company.users" :key="index">
                <span>Login: {{user.login}}</span><br/>
                <span>Id: {{user._id}}</span><br/>
                <span v-if="user.profile != null && user.profile.phones != null && user.profile.phones.length > 0">
                  Phone: {{user.profile.phones[0].number}}<br/>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-8">
          <ListInvoices :companyId="company._id.toString()" :showCompanyColumn="false"></ListInvoices>
          <ListWorkers :companyId="company._id.toString()" ></ListWorkers>
        </div>
      </div>

    </div>
  </div>
</template>


<script lang="ts">
import { Ref, ComputedRef, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import Select from '@fwk-client/components/panels/input/Select.vue';
import { authenticationTypes } from '@fwk-client/store/types';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';

import { useCompanyAdmin } from '../../composables/useCompanyAdmin';
import { useModuleAdmin } from '../../composables/useModuleAdmin';
import { languagesTypes } from '@fwk-client/store/types';

export default defineComponent({
    props: {
        
    },
    components: {
      Select,
      Info : () => import(/* webpackChunkName: "panels-company-info" */ './Info.vue'),
      ListInvoices : () => import(/* webpackChunkName: "panels-company-listInvoices" */ './ListInvoices.vue'),
      ListWorkers : () => import(/* webpackChunkName: "panels-company-listWorkers" */ '../../../../components/panels/eurofiscalis/company/ListWorkers.vue')
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();
      const { modules, selectedModule } = useModuleAdmin(props, context);
      const { selectedCompany, isCompanyLoading } = useCompanyAdmin(props, context);

      const updateItemModal:Ref<HTMLElement|null> = ref(null);
      const removeItemModal:Ref<HTMLElement|null> = ref(null);
      const publishItemModal:Ref<HTMLElement|null> = ref(null);

      const itemToUpdate:Ref<any> = ref({});

      const hasUserRole = $store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

      const currentLanguageCode:ComputedRef<string> = computed(() => {
        return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
      })

      const isListLoading = computed(() => {
        return isCompanyLoading.value
      })

      return { 
        apiRoles,
        hasUserRole,
        isListLoading,
        company: selectedCompany,
        updateItemModal,
        itemToUpdate,
        removeItemModal,
        publishItemModal
      }
    }
})
</script>