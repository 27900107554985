<template>
    
    <form role="form" @submit="onFormSubmit" name="module-switch-form">
      
      <Select
        v-if="listModules.length > 0"
        :label="$t('company.module.switch.label')"
        :placeholder="$t('company.module.switch.select_placeholder')"
        :selectOptions="{
          options: listModules,
          getOptionLabel : option => option.name
        }"
        v-bind:value.sync="form.module" 
        :labelAsColumn="false"
      />
      
      <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('company.module.switch.button') }}</button>
    </form>
</template>

<style>
  form[name=module-switch-form] {
    display: flex;
  }

  form[name=module-switch-form] > div {
    display: flex;
    margin-bottom: 0;
    flex-grow:1;
  }

  form[name=module-switch-form] > div > label {
    margin-right:10px;
    line-height: 36px;
    margin-bottom: 0px;
  }

  form[name=module-switch-form] > div > div {
    flex-grow:1;
  }

  form[name=module-switch-form] > button {
    margin-left:10px;
  }
</style>

<script lang="ts"> 
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import Select from '@fwk-client/components/panels/input/Select.vue';
import * as Ladda from 'ladda';
import { languagesTypes } from '@fwk-client/store/types';

import { useModuleAdmin } from '../../composables/useModuleAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
      Select
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { modules, selectedModule, selectModule } = useModuleAdmin(props, context);

      const form:any = reactive({
        module: selectedModule ? selectedModule.value.module : undefined
      })
    
      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var button:HTMLButtonElement|null = document.querySelector( 'form[name=module-switch-form] button' );
        laddaSubmit = Ladda.create(button!);
      })

      const onFormSubmit = (evt:Event) => {
        // We prevent submit of the page
        evt.preventDefault();

        laddaSubmit!.start();
        selectModule(form.module._id).then(() => {
          laddaSubmit!.stop();
        });
      }

      const listModules = computed(() => {
        return modules.value.map((module:any) => {
          return module.module;
        })
      })

      return { 
        listModules,
        form,
        selectedModule,
        onFormSubmit
      }
    }
})
</script>