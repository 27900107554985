import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { authenticationTypes } from '@fwk-client/store/types';
import { types as companyTypes } from '@root/src/client/modules/company/store';

import { useModuleAdmin } from "./useModuleAdmin";

interface CompanyAdminInput {
}

export function useCompanyAdmin(props:CompanyAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();

  const { selectModule, selectedModule, callModuleAdmin, updateListModules } = useModuleAdmin(props, {emit});
  const hasUserRole = store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

  var isCompanyLoading:Ref<boolean> = ref(false);

  const updateSelectedCompany = async () => {

    isCompanyLoading.value = true;
    try {
      var response = await callModuleAdmin('/'+selectedModule.value.module._id+'/company');
      store.commit('company/'+companyTypes.mutations.SET_USER_SELECTED_COMPANY, response.company ? response.company : []);
    }
    catch(error:any) {
      console.log(error);
    }
    isCompanyLoading.value = false;
  }
  if(selectedModule.value.module) {
    updateSelectedCompany();
  }

  const selectedCompany = computed(() => {
    return store.getters['company/'+companyTypes.getters.GET_USER_SELECTED_COMPANY];
  })

  var isProductionCompanyLoading:Ref<boolean> = ref(false);
  const getProductionCompany = (company:any) => {
    var results:{company:any, retrieved:boolean} = {
      retrieved: false,
      company: {}
    }

    isProductionCompanyLoading.value = true;

    return callModuleAdmin('/'+selectedModule.value.module._id+'/company/'+company._id+'/production').then((response:any) => {
      isProductionCompanyLoading.value = false;
      if(response.retrieved) {
        results.retrieved = true;
        results.company = response.company
      }
      return results;
    });
  }

  const companyForm:any = reactive({
    name : "",
    size: undefined,
    revenue : undefined,
    web : undefined,
    identifiers : undefined,
    socialMedias : undefined,
    phones : undefined,
    emails : undefined,
    address : undefined,
    vatNumber : undefined,
    source : undefined,
    logo : null
  });

  const updateCompanyFormForUpdate = (company:any) => {
    companyForm._id = (company) ? company._id : undefined;
    companyForm.name = (company) ? company.name : undefined;
    companyForm.size = (company) ? company.size : undefined;
    companyForm.revenue  = (company) ? company.revenue : undefined;
    companyForm.web  = (company) ? company.web : undefined;
    companyForm.identifiers  = (company) ? company.identifiers : undefined;
    companyForm.socialMedias  = (company) ? company.socialMedias : undefined;
    companyForm.phones  = (company) ? company.phones : undefined;
    companyForm.emails  = (company) ? company.emails : undefined;
    companyForm.address  = (company) ? company.address : undefined;
    companyForm.vatNumber  = (company) ? company.vatNumber : undefined;
    companyForm.source  = (company) ? company.source : undefined;
    companyForm.logo = null
  }

  const createCompany = async () => {

    var results:{created:boolean, company:any} = {
      created: false,
      company: {}
    }

    var input = {
      "name" : companyForm.name,
    }

    try {
      var response = await callModuleAdmin('/companies/create', input);
      if(response.created) {  

        results.created = true;
        results.company = response.company;
        companyForm.name = "";

        
      }
    }
    catch(error:any) {
      console.log(error);
    }

    return results;
  }

  const updateCompany = async () => {

    var results:{updated:boolean, company:any} = {
      updated: false,
      company: {}
    }

    var input = {
      "name" : companyForm.name,
      "size" : companyForm.size,
      "revenue" : companyForm.revenue,
      "web" : companyForm.web,
      "identifiers" : companyForm.identifiers,
      "socialMedias" : companyForm.socialMedias,
      "phones" : companyForm.phones,
      "emails" : companyForm.emails,
      "address" : companyForm.address,
      "vatNumber" : companyForm.vatNumber,
      "source" : companyForm.source
    }

    var formData = new FormData();
    if(companyForm.logo != null) {
      formData.append("logo", companyForm.logo!, (companyForm.logo! as File).name);  
    }

    try {
      var response = await callModuleAdmin('/'+companyForm._id+'/update', input);
      if(response.updated) {  
        results.updated = true;
        results.company = response.company;     
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return results;
  }

  const removeCompany = async (companyID:string) => {

    var result:any = {
      removed:false
    }

    try {
      var response = await callModuleAdmin('/'+companyID+'/remove');
      if(response.removed) {
        result.removed = true;
        updateListModules();
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  // We check when there is a new agency selected
  watch(
    selectedModule,
    (val:any, oldVal:any) => {
      // We update the categories when the selected agency is updated
      updateSelectedCompany();
    },
    { deep: false }
  )

  return {
    isCompanyLoading,
    isProductionCompanyLoading,
    getProductionCompany,
    updateCompanyFormForUpdate,
    createCompany,
    updateCompany,
    removeCompany,
    selectedCompany,
    companyForm
  }
  
}