import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as moduleTypes } from '../store';

import { roles as moduleRoles } from '@igotweb-node-api/api/modules/company/src/roles/index';
import { GenericModuleAdminInput, useGenericModuleAdmin } from "@root/src/client/composables/admin/useGenericModuleAdmin";

export const apiModulePath = '/api/admin/company';

export const genericModuleInput:GenericModuleAdminInput = {
  apiModulePath: apiModulePath,
  storeModule: 'company',
  storeModuleTypes: moduleTypes
}

interface ModuleAdminInput {
}

export function useModuleAdmin(props:ModuleAdminInput, context:any) { 
  const app = getApp();
  const store = useStore();

  const { 
    callModuleAdmin,
    emitModuleAdmin,
    isListLoading,
    modules,
    getModuleFromID,
    selectedModule,
    selectModule,
    moduleForm,
    createModule,
    updateModule,
    removeModule,
    isModuleUpdateAllowed,
    isModuleCreateAllowed,
    isModuleRemoveAllowed,
    updateModuleFormForUpdate,
    getAdminSettings,
    updateAdminSettings,
    getClientSettings,
    updateClientSettings,
    updateListModules } = useGenericModuleAdmin(genericModuleInput, context);

  // We can add specific module logic


  return {
    callModuleAdmin,
    emitModuleAdmin,
    isListLoading,
    modules,
    getModuleFromID,
    selectedModule,
    selectModule,
    moduleForm,
    createModule,
    updateModule,
    removeModule,
    isModuleUpdateAllowed,
    isModuleCreateAllowed,
    isModuleRemoveAllowed,
    updateModuleFormForUpdate,
    getAdminSettings,
    updateAdminSettings,
    getClientSettings,
    updateClientSettings,
    moduleRoles,
    updateListModules
  }
  
}